import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Section from './components/Section';
import TargetAudience from './components/TargetAudience';
import Learning from './components/Learning';
import CurriculumSection from './components/CurriculumSection';
import Footer from './components/Footer';
import CallToAction from './components/CallToAction';

const App: React.FC = () => {
  const curriculumItems = [
    {
      main: '1. Fundamentos Essenciais de IA',
      subitems: ['Introdução à IA e História', 'Tipos de IA: ANI, AGI e ASI']
    },
    {
      main: '2. Explorando o Aprendizado de Máquina e Redes Neurais',
      subitems: ['Regressão Linear e Algoritmo de Classificação', 'Redes Neurais: Estrutura e Funções de Ativação']
    },
    {
      main: '3. Compreendendo o Processamento de Linguagem Natural',
      subitems: ['Técnicas de NLP: Tokenização e Análise Sintática', 'Modelos de Linguagem: Word2Vec e BERT']
    },
    {
      main: '4. Visão Computacional e Identificação de Padrões',
      subitems: ['Redes Neurais Convolucionais (CNNs)', 'Redes Neurais Recorrentes (RNNs)']
    },
    {
      main: '5. Aplicações Práticas em Diversos Setores',
      subitems: ['Aplicações em Saúde, Finanças, e Automotiva', 'Ética na IA: Viés nos Algoritmos e Práticas Responsáveis']
    }
  ];

  return (
    <div>
      <Header />
      <Hero />
      <Section
        title="Descubra o Futuro com o Curso Online de Inteligência Artificial do Dr. Dheiver Santos!"
        text="Explore o mundo da Inteligência Artificial com o curso do Dr. Dheiver Santos. Com 10 aulas em vídeo e slides detalhados, você dominará desde os conceitos fundamentais até as aplicações avançadas. Prepare-se para se destacar com habilidades de elite em IA e encare o futuro com confiança!"
      />
      <TargetAudience />
      <Learning />
      <CurriculumSection
        title="Conteúdo programático"
        items={curriculumItems}
      />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default App;
