import React from 'react';

const Header: React.FC = () => {
  const handleButtonClick = () => {
    window.open('https://pay.kiwify.com.br/HR1sIDN', '_blank');
  };

  return (
    <header className="bg-background py-6 shadow-md hidden md:block">
      <div className="container mx-auto flex justify-between items-center px-4">
        <h1 className="text-2xl font-bold text-primary">
          Domine IA
        </h1>
        <button 
          className="bg-primary text-white py-2 px-4 rounded-full hover:bg-opacity-90"
          onClick={handleButtonClick} 
        >
          Saiba mais
        </button>
      </div>
    </header>
  );
};

export default Header;
