import React from 'react';

const Footer: React.FC = () => {
  const handleLinkedInClick = () => {
    window.open('https://www.linkedin.com/in/dheiver-santos/', '_blank');
  };

  return (
    <footer className="bg-background text-white py-10">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="text-center md:text-left mb-4 md:mb-0">
          <h2 className="text-xl font-bold">Fale conosco</h2>
          <p>(51) 989889898</p>
          <p>R. Caxias do Sul, 95 - Operário, Novo Hamburgo - RS, 93315-132</p>
          <p>dheiver.santos@gmail.com</p>
          <p><a href="https://www.dheiver.com" className="text-primary">www.dheiver.com</a></p>
        </div>
        <div className="flex space-x-4">
          <button 
            onClick={handleLinkedInClick}
            className="text-text hover:text-blue-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 fill-current">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.3c-.966 0-1.75-.784-1.75-1.75s.784-1.75 1.75-1.75 1.75.784 1.75 1.75-.784 1.75-1.75 1.75zm13.5 12.3h-3v-5.568c0-1.33-.024-3.043-1.852-3.043-1.854 0-2.137 1.445-2.137 2.941v5.67h-3v-11h2.872v1.505h.041c.399-.755 1.375-1.553 2.832-1.553 3.027 0 3.586 1.993 3.586 4.585v6.463z"/>
            </svg>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
