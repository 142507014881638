import React from 'react';

const learningData = [
  {
    title: 'Fundamentos Essenciais',
    description: 'Domine os conceitos fundamentais da IA e desenvolva uma base sólida para o sucesso em sua jornada.',
    image: '/images/fundamentos.png',
  },
  {
    title: 'Aplicações Práticas',
    description: 'Aprenda a aplicar os princípios da IA em cenários do mundo real, preparando-se para desafios reais do mercado.',
    image: '/images/aplicacoes.png',
  },
  {
    title: 'Habilidades Avançadas',
    description: 'Aprofunde-se em áreas especializadas, como aprendizado de máquina avançado, visão computacional e processamento de linguagem natural.',
    image: '/images/avancadas.png',
  },
];

const Learning: React.FC = () => {
  return (
    <section className="bg-background text-white py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center max-w-2xl mx-auto">O que Você Vai Aprender:</h2>
        <div className="space-y-16">
          {learningData.map((learning, index) => (
            <div 
              key={index} 
              className={`flex flex-col md:flex-row ${index % 2 !== 0 ? 'md:flex-row-reverse' : ''} items-center`}>
              <div className="md:w-1/2 flex justify-center p-2">
                <img 
                  src={learning.image} 
                  alt={learning.title} 
                  className="w-3/4 h-auto rounded-3xl shadow-2xl" 
                />
              </div>
              <div className={`md:w-1/2 p-4 text-center md:text-left flex justify-center md:justify-start ${index % 2 !== 0 ? 'md:pl-8' : 'md:pr-8'}`}>
                <div className="max-w-md">
                  <h3 className="text-2xl font-semibold mb-4">{learning.title}</h3>
                  <p className="text-base">{learning.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Learning;
