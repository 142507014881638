import React from 'react';

interface SectionProps {
  title: string;
  text: string;
}

const Section: React.FC<SectionProps> = ({ title, text }) => {
  const handleMouseOver = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.currentTarget.classList.add('text-blue-600');
  };

  const handleMouseOut = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.currentTarget.classList.remove('text-blue-600');
  };

  return (
    <section className="bg-secondary text-white py-20">
      <div className="container mx-auto px-4 text-center md:max-w-3xl">
        <h2 className="text-3xl font-bold mb-6 cursor-pointer">
          {title.split('').map((letter, index) => (
            <span key={index} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              {letter}
            </span>
          ))}
        </h2>
        <p>{text}</p>
      </div>
    </section>
  );
};

export default Section;
