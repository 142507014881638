import React, { useState } from 'react';

interface CurriculumItemProps {
  title: string;
  items: { main: string, subitems?: string[] }[];
}

const CurriculumSection: React.FC<CurriculumItemProps> = ({ title, items }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-background text-white py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6 text-center">
          {title}
        </h2>
        <div className="space-y-4">
          {items.map((item, index) => (
            <div key={index} className="bg-secondary p-4 rounded-lg">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleToggle(index)}
              >
                <h3 className="text-xl font-semibold mb-2">
                  {item.main}
                </h3>
                <span className="text-2xl">
                  {openIndex === index ? '▲' : '▼'}
                </span>
              </div>
              {openIndex === index && item.subitems && (
                <ul className="list-disc pl-6 mt-2">
                  {item.subitems.map((subitem, subindex) => (
                    <li key={subindex}>{subitem}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CurriculumSection;