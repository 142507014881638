import React from 'react';
import { FaLock, FaBolt } from 'react-icons/fa';

const CallToAction: React.FC = () => {
  const handleButtonClick = () => {
    window.open('https://pay.kiwify.com.br/HR1sIDN', '_blank');
  };
  
  return (
    <section className="bg-secondary text-white py-16 border border-blue-600 p-6 ">
      <div className='rounded-md max-w-lg mx-auto text-center'>
        <h2 className="text-lg font-bold mb-4">Quanto custa ter acesso a tudo que você precisa aprender sobre Inteligência Artificial?</h2>
        <div className="text-4xl font-bold text-blue-600 mb-2">12x de R$ 16,43</div>
        <div className="text-[1.2rem] mb-4">ou R$ 197,23 à vista</div>
        <p className="text-lg mb-6">São apenas R$ 0,54 por dia para dominar todos os conceitos de Inteligência Artificial. Uma vida de mais conhecimento e oportunidades valem R$ 0,54 por dia para você?</p>
        <button className="bg-blue-600 text-white py-2 px-8 rounded-full pulse-button font-bold" onClick={handleButtonClick}>
          EU QUERO
        </button>
        <div className="flex justify-around mt-6">
          <div className="flex items-center">
            <FaLock className="mr-2 text-blue-600" />
            <span>100% seguro</span>
          </div>
          <div className="flex items-center">
            <FaBolt className="mr-2 text-blue-600" />
            <span>Acesso imediato</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
