import React, { useRef, useEffect } from 'react';

const Hero: React.FC = () => {
  const circleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const circle = circleRef.current;

    const handleMouseMove = (event: MouseEvent) => {
      if (circle) {
        const circleRect = circle.getBoundingClientRect();
        const circleX = circleRect.left + circleRect.width / 1;
        const circleY = circleRect.top + circleRect.height / 1;
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const distX = mouseX - circleX;
        const distY = mouseY - circleY;

        const angle = Math.atan2(distY, distX);
        const distance = Math.min(Math.sqrt(distX * distX + distY * distY), 40);

        const translateX = Math.cos(angle) * distance;
        const translateY = Math.sin(angle) * distance;

        circle.style.transform = `translate(${translateX}px, ${translateY}px)`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleButtonClick = () => {
    window.open('https://pay.kiwify.com.br/HR1sIDN', '_blank');
  };

  return (
    <section className="relative bg-background text-white overflow-hidden pt-20 md:pt-0">
      <div className="container mx-auto flex flex-col md:flex-row items-center px-4">
        <div className="md:w-1/2 z-10 text-center md:text-left">
          <h1 className="text-5xl md:text-7xl font-extrabold mb-6 leading-tight">
            Domine IA com <br/><span className="text-primary">Dr. Dheiver Santos</span>
          </h1>
          <button className="bg-blue-600 text-white py-3 px-8 rounded-full hover:bg-blue-700 transition duration-300" onClick={handleButtonClick}>
            Saiba mais
          </button>
        </div>
        <div className="md:w-1/2 mt-20 md:mt-0 relative flex justify-end">
          <div ref={circleRef} className="absolute bg-gradient-to-r from-transparent to-white rounded-full w-full h-full right-0 top-1/3 transform -translate-y-1/2"></div>
          <img src="/images/hero.png" alt="Domine IA" className="rounded-lg shadow-lg relative z-10" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
