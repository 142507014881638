import React from 'react';
import { FaLaptopCode, FaUserGraduate, FaBusinessTime } from 'react-icons/fa';

const audienceData = [
  {
    title: 'Profissionais de TI e Desenvolvedores',
    description: 'Ideal para quem deseja integrar IA em seus projetos.',
    icon: <FaLaptopCode className="text-text text-5xl mb-4" />,
  },
  {
    title: 'Estudantes e Pesquisadores',
    description: 'Para aqueles que buscam aprofundar seus conhecimentos em ciências da computação.',
    icon: <FaUserGraduate className="text-text text-5xl mb-4" />,
  },
  {
    title: 'Executivos e Empreendedores',
    description: 'Perfeito para quem quer usar IA para inovação e otimização de processos.',
    icon: <FaBusinessTime className="text-text text-5xl mb-4" />,
  },
];

const TargetAudience: React.FC = () => {
  return (
    <section className="bg-background text-text py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-12 text-center">Para quem é esse curso?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {audienceData.map((audience, index) => (
            <div key={index} className="bg-secondary p-8 rounded-lg text-center shadow-md transition-transform duration-300 hover:scale-105">
              <div className="flex justify-center mb-6">{audience.icon}</div>
              <h3 className="text-2xl font-semibold mb-4">{audience.title}</h3>
              <p>{audience.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TargetAudience;
